import { SmartSearchPageEnum } from '@app/sign-up/enums/smart-search-page.enum';
import { UserPageEnum } from '@app/sign-up/enums/user-page.enum';
import { FlowStepsModel } from '@app/sign-up/models/flow-step.model';
import { paths } from '@platform/paths';

export const FIRST_TIME_BUYER_FLOW = {
  SmartSearchBuyingProcessStage: 0,
  SmartSearchBasicDetails: 1,
  SmartSearchInitialRate: 2,
  SmartSearchLiveResults: 3,
  UserCreate: 4,
} as const;

export const FIRST_TIME_BUYER_FLOW_STEPS: FlowStepsModel<typeof FIRST_TIME_BUYER_FLOW> = {
  [FIRST_TIME_BUYER_FLOW.SmartSearchBuyingProcessStage]: {
    step: FIRST_TIME_BUYER_FLOW.SmartSearchBuyingProcessStage,
    name: 'SmartSearchBuyingProcessStage',
    page: {
      route: paths.signUp.SmartSearch,
      name: SmartSearchPageEnum.BuyingProcessStage,
    },
  },
  // [FIRST_TIME_BUYER_FLOW.SmartSearchFinancingScheme]: {
  //   step: FIRST_TIME_BUYER_FLOW.SmartSearchFinancingScheme,
  //   name: 'SmartSearchFinancingScheme',
  //   page: {
  //     route: paths.signUp.SmartSearch,
  //     name: SmartSearchPageEnum.FinancingScheme,
  //   },
  // },
  [FIRST_TIME_BUYER_FLOW.SmartSearchBasicDetails]: {
    step: FIRST_TIME_BUYER_FLOW.SmartSearchBasicDetails,
    name: 'SmartSearchBasicDetails',
    page: {
      route: paths.signUp.SmartSearch,
      name: SmartSearchPageEnum.BasicDetails,
    },
  },
  [FIRST_TIME_BUYER_FLOW.SmartSearchInitialRate]: {
    step: FIRST_TIME_BUYER_FLOW.SmartSearchInitialRate,
    name: 'SmartSearchInitialRate',
    page: {
      route: paths.signUp.SmartSearch,
      name: SmartSearchPageEnum.InitialRate,
    },
  },
  [FIRST_TIME_BUYER_FLOW.SmartSearchLiveResults]: {
    step: FIRST_TIME_BUYER_FLOW.SmartSearchLiveResults,
    name: 'SmartSearchLiveResults',
    page: {
      route: paths.signUp.SmartSearch,
      name: SmartSearchPageEnum.LiveResults,
    },
  },
  [FIRST_TIME_BUYER_FLOW.UserCreate]: {
    step: FIRST_TIME_BUYER_FLOW.UserCreate,
    name: 'UserCreate',
    page: {
      route: paths.signUp.User,
      name: UserPageEnum.UserCreate,
    },
  },
};
