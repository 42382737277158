import { HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod } from '@api-new/authservice';
import { ENUM_MortgagePartStepType, ENUM_MortgageProductPurpose, ENUM_MortgageType, ENUM_PropertyInvestmentType } from '@api-new/common';
import { HTTP_X_CompareMortgageProducts_Request } from '@api-new/mortgageservice';
import { PropertyFormValues } from '@app/sign-up/utils/property-form';
import { SmartSearchFormValues } from '@app/sign-up/utils/smart-search-form';

export function signUpSmartSearchToHttpMortgageProductsRequestAdapter(
  values: SmartSearchFormValues,
  creationMethod: HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod | null,
  property: PropertyFormValues | null,
  companyId: string,
): HTTP_X_CompareMortgageProducts_Request {
  let mortgageType: ENUM_MortgageType = ENUM_MortgageType.MORTGAGE_TYPE_UNSPECIFIED;
  let mortgageProductPurpose: ENUM_MortgageProductPurpose = ENUM_MortgageProductPurpose.MORTGAGE_PRODUCT_PURPOSE_UNSPECIFIED;
  let propertyPostcode: string | undefined;

  switch (creationMethod) {
    case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_REMORTGAGE_NOW: {
      mortgageProductPurpose = ENUM_MortgageProductPurpose.MORTGAGE_PRODUCT_PURPOSE_REMORTGAGE;
      if (property != null) {
        mortgageType =
          property.investmentType === ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_BUY_TO_LET
            ? ENUM_MortgageType.MORTGAGE_TYPE_BUY_TO_LET
            : ENUM_MortgageType.MORTGAGE_TYPE_RESIDENTIAL;
        propertyPostcode = property.address.postcode ?? undefined;
      }
      break;
    }
    case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_FIRST_TIME_BUYER: {
      mortgageProductPurpose = ENUM_MortgageProductPurpose.MORTGAGE_PRODUCT_PURPOSE_FIRST_TIME_BUYER;
      mortgageType = ENUM_MortgageType.MORTGAGE_TYPE_RESIDENTIAL;
      break;
    }
    case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_MOVING_HOME: {
      mortgageProductPurpose = ENUM_MortgageProductPurpose.MORTGAGE_PRODUCT_PURPOSE_HOUSE_MOVE;
      mortgageType = ENUM_MortgageType.MORTGAGE_TYPE_RESIDENTIAL;
      break;
    }
    case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_BUY_TO_LET: {
      mortgageType = ENUM_MortgageType.MORTGAGE_TYPE_BUY_TO_LET;
      break;
    }
  }

  return {
    minimumInitialRatePeriodInMonths: values.initialRate.comparisonPeriod[0] * 12,
    maximumInitialRatePeriodInMonths: values.initialRate.comparisonPeriod[1] * 12,
    preferredComparisonPeriodInMonths: values.initialRate.comparisonPeriod[0] * 12,
    lenderId: values.liveResults.lenderId ?? undefined, // TODO: Delete?
    mortgageParts: [
      {
        loanAmount: values.basicDetails.propertyPrice - values.basicDetails.deposit,
        repaymentType: values.liveResults.repaymentType,
        termInMonths: values.basicDetails.newTermInMonths,
      },
    ],
    mortgageType,
    mortgageProductPurpose,
    propertyValue: values.basicDetails.propertyPrice,
    propertyPostcode,
    propertyIsCompanyOwned: property?.additionalInfo.isCompanyOwned,
    includeInitialRateTypeFixed: values.initialRate.initialInterestRateType.includes(
      ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_FIXED,
    ),
    includeInitialRateTypeVariable: values.initialRate.initialInterestRateType.includes(
      ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_VARIABLE,
    ),
    companyId,
  };
}
