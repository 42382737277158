// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_ManualPropertyValueTypeInspectionSurveyor.proto

export enum ENUM_ManualPropertyValueTypeInspectionSurveyor {
  MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_UNSPECIFIED = 'MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_UNSPECIFIED',
  MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_ESTATE_AGENT = 'MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_ESTATE_AGENT',
  MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_AA_CENTRAL_SERVICES = 'MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_AA_CENTRAL_SERVICES',
  MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_ALLIED_SURVEYORS = 'MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_ALLIED_SURVEYORS',
  MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_CONNELLS = 'MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_CONNELLS',
  MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_COUNTRYWIDE_SURVEYORS = 'MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_COUNTRYWIDE_SURVEYORS',
  MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_STIRLING_ACKROYD = 'MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_STIRLING_ACKROYD',
  MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_VALUNATION = 'MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_VALUNATION',
  MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_OTHER = 'MANUAL_PROPERTY_VALUE_TYPE_INSPECTION_SURVEYOR_OTHER',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
