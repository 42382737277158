import { Injectable, inject } from '@angular/core';
import { HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod } from '@api-new/authservice';
import { MortgageApiService } from '@api-new/mortgageservice';
import { ENTRY_FLOW } from '@app/sign-up/constants/entry-flow';
import { MORTGAGE_MONITOR_FLOW } from '@app/sign-up/constants/mortgage-monitor-flow';
import { MOVING_HOME_FLOW } from '@app/sign-up/constants/moving-home-flow';
import { REMORTGAGE_NOW_FLOW } from '@app/sign-up/constants/remotgage-now-flow';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/models/app-state.model';
import { LocalStorageSyncService } from '@shared/services/local-storage-sync.service';
import { setSignUpFlowCurrentStep } from '@shared/store/sign-up/flow/flow.actions';
import { selectSignUpFlowData } from '@shared/store/sign-up/flow/flow.selectors';
import {
  loadSignUpMortgageFromStorage,
  loadSignUpMortgageFromStorageSuccess,
  saveSignUpMortgageToStorage,
  skipSignUpMortgageCreation,
} from '@shared/store/sign-up/mortgage/mortgage.actions';
import { selectSignUpMortgageState } from '@shared/store/sign-up/mortgage/mortgage.selectors';
import { signUpMortgageSelectorName } from '@shared/store/sign-up/mortgage/mortgage.state';
import { tap, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SignUpMortgageEffects {
  private readonly actions$ = inject(Actions);
  private readonly store: Store<AppState> = inject(Store<AppState>);
  private readonly localStorageSyncService = inject(LocalStorageSyncService);
  private readonly mortgageApiService = inject(MortgageApiService);

  loadMortgageFromStorage = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSignUpMortgageFromStorage),
      map(() => loadSignUpMortgageFromStorageSuccess({ state: this.localStorageSyncService.loadFromStorage(signUpMortgageSelectorName) })),
    ),
  );

  saveMortgageToStorage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveSignUpMortgageToStorage),
        withLatestFrom(this.store.select(selectSignUpMortgageState)),
        tap(([_, mortgageState]) => {
          this.localStorageSyncService.saveToStorage(signUpMortgageSelectorName, mortgageState);
        }),
      ),
    { dispatch: false },
  );

  skipMortgageCreation = createEffect(() =>
    this.actions$.pipe(
      ofType(skipSignUpMortgageCreation),
      withLatestFrom(this.store.select(selectSignUpFlowData)),
      map(([_, flowData]) => {
        switch (flowData.creationMethod) {
          case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_MORTGAGE_MONITOR:
            return setSignUpFlowCurrentStep({ step: MORTGAGE_MONITOR_FLOW.UserCreate });
          case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_MOVING_HOME:
            return setSignUpFlowCurrentStep({ step: MOVING_HOME_FLOW.UserCreate });
          case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_REMORTGAGE_NOW:
            return setSignUpFlowCurrentStep({ step: REMORTGAGE_NOW_FLOW.SmartSearchLiveResults });
        }

        return setSignUpFlowCurrentStep({ step: ENTRY_FLOW.Landing });
      }),
    ),
  );
}
