import { Injectable, inject } from '@angular/core';
import { HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod } from '@api-new/authservice';
import { MortgageApiService } from '@api-new/mortgageservice';
import { ENTRY_FLOW } from '@app/sign-up/constants/entry-flow';
import { REMORTGAGE_NOW_FLOW } from '@app/sign-up/constants/remotgage-now-flow';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/models/app-state.model';
import { LocalStorageSyncService } from '@shared/services/local-storage-sync.service';
import { selectSignUpBrandingCompanyId } from '@shared/store/sign-up/branding/branding.selectors';
import { setSignUpFlowCurrentStep } from '@shared/store/sign-up/flow/flow.actions';
import { selectSignUpFlowCreationMethod, selectSignUpFlowData } from '@shared/store/sign-up/flow/flow.selectors';
import { selectSignUpPropertyData } from '@shared/store/sign-up/property/property.selectors';
import { signUpSmartSearchToHttpMortgageProductsRequestAdapter } from '@shared/store/sign-up/smart-search/adapters/signUpSmartSearchToHttpMortgageProductsRequestAdapter';
import {
  getSignUpSmartSearchMortgageProducts,
  getSignUpSmartSearchMortgageProductsFailure,
  getSignUpSmartSearchMortgageProductsSuccess,
  loadSignUpSmartSearchFromStorage,
  loadSignUpSmartSearchFromStorageSuccess,
  setSignUpSmartSearchForm,
  skipSignUpSmartSearchCreation,
} from '@shared/store/sign-up/smart-search/smart-search.actions';
import { selectSignUpSmartSearchState } from '@shared/store/sign-up/smart-search/smart-search.selectors';
import { signUpSmartSearchSelectorName } from '@shared/store/sign-up/smart-search/smart-search.state';
import { of, tap, withLatestFrom } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

@Injectable()
export class SignUpSmartSearchEffects {
  private readonly actions$ = inject(Actions);
  private readonly store: Store<AppState> = inject(Store);
  private readonly localStorageSyncService = inject(LocalStorageSyncService);
  private readonly mortgageApiService = inject(MortgageApiService);

  loadSmartSearchFromStorage = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSignUpSmartSearchFromStorage),
      map(() =>
        loadSignUpSmartSearchFromStorageSuccess({ state: this.localStorageSyncService.loadFromStorage(signUpSmartSearchSelectorName) }),
      ),
    ),
  );

  saveSmartSearchToStorage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setSignUpSmartSearchForm),
        withLatestFrom(this.store.select(selectSignUpSmartSearchState)),
        tap(([_, smartSearchState]) => {
          this.localStorageSyncService.saveToStorage(signUpSmartSearchSelectorName, smartSearchState);
        }),
      ),
    { dispatch: false },
  );

  getSignUpSmartSearchMortgageProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(getSignUpSmartSearchMortgageProducts),
      withLatestFrom(
        this.store.select(selectSignUpFlowCreationMethod),
        this.store.select(selectSignUpPropertyData),
        this.store.select(selectSignUpBrandingCompanyId),
      ),
      filter((latestData): latestData is [(typeof latestData)[0], (typeof latestData)[1], (typeof latestData)[2], string] => {
        const [, companyId] = latestData;
        return companyId != null;
      }),
      switchMap(([values, creationMethod, property, companyId]) =>
        this.mortgageApiService.HTTP_X_CompareMortgageProducts(
          signUpSmartSearchToHttpMortgageProductsRequestAdapter(values, creationMethod, property, companyId),
        ),
      ),
      map((response) => getSignUpSmartSearchMortgageProductsSuccess(response)),
      catchError((error) => of(getSignUpSmartSearchMortgageProductsFailure({ error }))),
    ),
  );

  skipMortgageCreation = createEffect(() =>
    this.actions$.pipe(
      ofType(skipSignUpSmartSearchCreation),
      withLatestFrom(this.store.select(selectSignUpFlowData)),
      map(([_, flowData]) => {
        switch (flowData.creationMethod) {
          case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_REMORTGAGE_NOW:
            return setSignUpFlowCurrentStep({ step: REMORTGAGE_NOW_FLOW.UserCreate });
        }

        return setSignUpFlowCurrentStep({ step: ENTRY_FLOW.Landing });
      }),
    ),
  );
}
